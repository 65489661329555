@tailwind base;
@tailwind components;
@tailwind utilities;


.accordion-overrides {
  .accordion-header,
  .accordion-body {
    visibility: visible !important;
  }
}

.custom-quill .ql-editor {
  min-height: 600px; /* Adjust the height value as needed */
}

@mixin breakpoint($point) {
  @if $point==sm {
    @media (min-width: 576px) {
      @content;
    }
  } @else if $point==max-sm {
    @media (max-width: 575px) {
      @content;
    }
  } @else if $point==md {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $point==max-md {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $point==lg {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $point==max-lg {
    @media (max-width: 991px) {
      @content;
    }
  } @else if $point==xl {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $point==max-xl {
    @media (max-width: 1199px) {
      @content;
    }
  } @else if $point==xxl {
    @media (min-width: 1400px) {
      @content;
    }
  } @else if $point==max-xxl {
    @media (max-width: 1399px) {
      @content;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500&display=swap");

:root {
  --title: #2d3e4d;
  --text: #556571;
  --body: #f6f8fb;
  --body-2: #fefeff;
  --section: #e7eef5;
  --main-bg: #eff4fa;
  --base: #23b574;
  --base-2: #064b84;
  --base-rgb-2: 6, 75, 132;
  --white: #ffffff;
  --body-fonts: "Poppins", sans-serif;
  --heading-fonts: "Poppins", sans-serif;
  --shadow: 10px 20px 40px 0px rgba(12, 87, 155, 0.15);
  --border: #ced5da;
}

body {
  background: var(--body);
  font-family: var(--body-fonts);
  color: var(--text);
  font-weight: normal;
  font-size: 18px;
  @include breakpoint(max-md) {
    font-size: 14px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--title);
  font-family: var(--heading-fonts);
  font-weight: 600;
}

h1 {
  font-size: 60px;
  @include breakpoint(max-xl) {
    font-size: 50px;
  }
  @include breakpoint(max-lg) {
    font-size: 42px;
  }
}
h2 {
  font-size: 48px;
}
h3 {
  font-size: 30px;
}
.pricing-card-title {
  font-size: 26px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}
.pricing-card-limit {
  font-size: 14px;
}
h6 {
  font-size: 15px;
}
@include breakpoint(max-md) {
  h1 {
    font-size: 34px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 22px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 16px;
  }
}
@include breakpoint(max-sm) {
  h1 {
    font-size: 24px;
    line-height: 34px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 15px;
  }
}
ul {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
  }
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  transition: all ease 0.3s;
}
.font-light {
  font-weight: 300;
}
.font-regular {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-extra-bold {
  font-weight: 800;
}
.font-black {
  font-weight: 900;
}
.container {
  position: relative;
  z-index: 1;
  @include breakpoint(max-sm) {
    padding-inline: 24px;
  }
}
@include breakpoint(xxl) {
  .container {
    max-width: 1254px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.bg-section {
  background: var(--section) !important;
}
.bg-body {
  background: var(--body) !important;
}
.banner-section {
  padding: 231px 0 384px;
  @include breakpoint(max-md) {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  @include breakpoint(max-sm) {
    padding-top: 130px;
    padding-bottom: 180px;
  }
}
.banner-btn {
  display: inline-block;
  padding: 32px 80px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: var(--body-2);
  position: relative;
  border-radius: 100px;
  .border,
  &::before,
  &::after {
    position: absolute;
    inset: 0;
    content: "";
    background: linear-gradient(148deg, #23b574 0%, #0c579b 100%);
    z-index: -1;
    border-radius: 100px;
    transition: all ease 0.3s;
  }
  .border {
    border: 2px solid rgba(255, 255, 255, 0.25) !important;
    z-index: 1;
    background: transparent;
  }

  &::after {
    background: linear-gradient(148deg, #0c579b 0%, #23b574 100%);
    opacity: 0;
  }
  &:hover {
    color: var(--body-2);
    &::after {
      opacity: 1;
    }
    &::before {
      opacity: 0;
    }
  }
  @include breakpoint(max-xl) {
    font-size: 20px;
    font-weight: 500;
    padding: 16px 50px;
  }
  @include breakpoint(max-sm) {
    font-size: 16px;
    font-weight: 500;
    padding: 12px 40px;
  }
}
.banner-content {
  max-width: 1060px;
  margin-inline: auto;
  text-align: center;
  .title {
    font-weight: 600;
    color: var(--body-2);
    margin-bottom: 32px;
    span {
      background: linear-gradient(90deg, #94ffee 0%, #83c4fe 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: inline-block;
    }
  }
  color: var(--body-2);
  .text {
    max-width: 769px;
    margin: 0 auto 32px;
  }
}
.banner-img {
  position: relative;
  margin-top: -308px;
  @media screen and (max-width: 1560px) {
    max-width: 1340px;
  }
  @media screen and (max-width: 1490px) {
    width: calc(100% + 80px);
  }
  @media screen and (max-width: 1420px) {
    width: calc(100% + 50px);
  }
  @media screen and (max-width: 1240px) {
    width: calc(100% + 20px);
  }
  @include breakpoint(max-md) {
    margin-top: -150px;
  }
  @media screen and (max-width: 576px) {
    width: 100%;
    margin-top: -135px;
  }
  @media screen and (max-width: 425px) {
    margin-top: -125px;
  }
}
.logo {
  display: block;
  img {
    width: 100%;
  }
}
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 23px 0;
  top: 30px;
  transition: all ease 0.3s;
  z-index: 999;
  &.active {
    top: 0;
    background: var(--base-2);
  }
}
.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    max-width: 191px;
    @include breakpoint(max-sm) {
      width: 109px;
    }
  }
}
.menu {
  display: flex;
  column-gap: 44px;
  li {
    a {
      color: #d6e5f1;
      &:hover {
        color: var(--base);
      }
    }
  }
  @include breakpoint(max-xl) {
    column-gap: 26px;
  }
}
.header-buttons {
  display: flex;
  align-items: center;
  gap: 24px;
}
.play-button {
  background: transparent;
  outline: none;
  border: none;
  color: var(--body-2);
}
.header-btn {
  background: var(--body-2);
  padding: 14px 40px;
  border-radius: 28px;
  color: var(--base);
  &:hover {
    background: var(--base);
    color: var(--white);
  }
}
.menu-area {
  @include breakpoint(max-lg) {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    background: var(--base-2);
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 15px;
    transition: 0.5s ease-in-out;
    .close-menu {
      color: var(--white);
      top: 15px;
      right: 15px;
      position: absolute;
    }
    &:not(.active) {
      opacity: 0;
      visibility: hidden;
      transform: translateX(-80%);
    }
    .menu {
      flex-direction: column;
      gap: 15px;
      text-align: center;
      width: 100%;
      margin-top: auto;
      li {
        a {
          display: block;
          padding: 10px;
          font-size: 18px;
        }
      }
    }
    .header-buttons {
      font-size: 15px;
      margin-top: auto;
      justify-content: center;
      width: 100%;
      padding-bottom: 15px;
      .header-btn {
        padding: 12px 30px;
      }
    }
  }
}
.section-header {
  text-align: center;
  position: relative;
  .title {
    margin-bottom: 37px;
    span {
      color: var(--base);
    }
  }
  max-width: 769px;
  margin: 0 auto 80px;
  color: #556571;
  position: relative;
  &::before {
    content: attr(data-title);
    color: rgba(174, 202, 225, 0.13);
    font-weight: 600;
    font-size: 256.941px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    @include breakpoint(max-xl) {
      font-size: 220px;
    }
    @include breakpoint(max-lg) {
      font-size: 180px;
    }
    @include breakpoint(max-md) {
      font-size: 150px;
    }
    @include breakpoint(max-md) {
      font-size: 60px;
      top: 8px;
    }
  }
  @include breakpoint(max-md) {
    margin-bottom: 55px;
  }
  @include breakpoint(max-sm) {
    margin-bottom: 39px;
  }
}
.feature-section {
  padding: 105px 0 80px;
  overflow: hidden;
  @include breakpoint(max-sm) {
    padding: 65px 0 80px;
  }
  .section-header {
    &::before {
      @media screen and (min-width: 375px) and (max-width: 425px) {
        transform: translate(-50%, -50%) scale(1.25);
      }
    }
  }
}
.feature-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 30px;
  row-gap: 40px;
  &:nth-child(odd) {
    flex-direction: row-reverse;
  }
  .feature-img {
    width: 100%;
    max-width: 500px;
  }
  .feature-cont {
    width: 100%;
    max-width: 600px;
    .title {
      width: 0;
      flex-grow: 1;
    }
    .header-area {
      // gap: 17px;
      margin-bottom: 16px;
      @include breakpoint(max-sm) {
        // gap: 10px;
      }
    }
    .text {
      // padding-left: 88px;
    }
  }
  &:not(:last-child) {
    margin-bottom: 50px;
    @include breakpoint(md) {
      margin-bottom: 80px;
    }
  }
  @include breakpoint(max-xl) {
    .feature-img {
      width: 350px;
    }
    .feature-cont {
      width: 0;
      flex-grow: 1;
      .title {
        @include breakpoint(sm) {
          font-size: 24px;
        }
      }
      .icon {
        svg {
          width: 50px;
          height: 50px;
        }
      }
      .text {
        // padding-left: 70px;
        font-size: 15px;
      }
    }
  }
  @include breakpoint(max-lg) {
    .feature-img {
      width: 300px;
    }
  }
  @include breakpoint(max-md) {
    flex-wrap: wrap-reverse;
    .feature-img {
      max-width: 90%;
    }
    .feature-cont,
    .feature-img {
      width: 100%;
      .header-area {
        margin-bottom: 10px;
      }
      .icon {
        svg {
          width: 40px;
          height: 40px;
        }
      }
      .text {
        // padding-left: 50px;
      }
    }
  }
  @include breakpoint(max-sm) {
    .feature-cont {
      .text {
        font-size: 12px;
      }
    }
  }
}
.video-player-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  transition: all ease 0.3s;
  padding: 16px;
  .player {
    z-index: 9;
    position: relative;
    animation: zoomIn 0.7s;
    -webkit-animation: zoomIn 0.7s;
    -moz-animation: zoomIn 0.7s;
    width: 100% !important;
    max-width: 885px !important;
    height: 500px !important;
    @include breakpoint(max-sm) {
      height: 320px !important;
    }
  }
  .__backdrop {
    position: absolute;
    inset: 0;
    background: var(--title);
    opacity: 0.8;
    z-index: 1;
    cursor: zoom-out;
  }
  transition: all ease 0.3s;
  &:not(.active) {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes zoomIn {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
.pricing-section-top {
  padding: 175px 0 420px;
  background: var(--base-2);
  @include breakpoint(max-md) {
    padding-top: 80px;
  }
  .section-header {
    color: var(--body-2);
    margin-bottom: 40px;
    @include breakpoint(max-sm) {
      margin-bottom: 30px;
    }
    .title {
      color: var(--body-2);
    }
    &::before {
      top: 35px;
      @include breakpoint(max-sm) {
        top: 10px;
      }
      @media screen and (min-width: 375px) and (max-width: 425px) {
        transform: translate(-50%, -50%) scale(1.25);
      }
    }
  }
}
.pricing-wrapper {
  margin-top: -370px;
  position: relative;
  z-index: 1;
}
.switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  * {
    margin: 0;
    padding-bottom: 0;
    padding-top: 0;
    border: none;
    outline: none;
  }
  .form-check-input {
    height: 30px;
    width: 63px;
    box-shadow: none;
    &:checked {
      background-color: var(--base);
    }
  }
  .btn {
    color: var(--body-2);
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
}
.pricing-section {
  padding-bottom: 80px;
  @include breakpoint(max-sm) {
    padding-bottom: 88px;
  }
}
.plan-card {
  box-shadow: 10px 20px 40px 0px rgba(12, 87, 155, 0.15);
  background: var(--white);
  border-radius: 8px;
  padding: 22px;
  @include breakpoint(max-xl) {
    padding: 24px;
  }
  &-header {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 40px;
    .cont {
      width: 0;
      flex-grow: 1;
      .name {
        margin-bottom: 5px;
      }
      .limit {
        font-weight: 500;
        margin: 0;
      }
      .text {
        font-size: 16px;
      }
    }
    @include breakpoint(max-xxl) {
      .cont {
        .limit {
          font-size: 12.5px;
        }
        .text {
          margin-top: 8px;
          display: block;
          font-size: 13px;
        }
      }
    }
    @include breakpoint(max-xl) {
      @include breakpoint(lg) {
        gap: 15px;
        .icon {
          svg {
            width: 60px;
            height: 60px;
          }
        }
        .cont {
          .name {
            font-size: 20px;
          }
          .limit {
            font-size: 12px;
          }
        }
      }
    }
    @include breakpoint(max-sm) {
      gap: 25px;
      .icon {
        svg {
          width: 50px;
          height: 50px;
        }
      }
      .cont {
        .name {
          font-size: 24px;
        }
        .limit {
          font-size: 16px;
        }
        .text {
          font-size: 14px;
        }
      }
    }
  }
  &-pricing {
    font-size: 26px;
    line-height: 1;
    font-weight: 700;
    color: var(--base-2);
    sub {
      font-size: 15px;
      color: var(--text);
      font-weight: 400;
      margin: 0;
      bottom: 2px;
    }
    margin-bottom: 32px;
    @include breakpoint(max-sm) {
      font-size: 24px;
      sub {
        font-size: 16px;
      }
    }
  }
  .subtitle {
    line-height: 1;
    margin-bottom: 32px;
  }
  .feature-txt {
    display: flex;
    flex-direction: column;
    gap: 14.24px;
    li {
      gap: 10px;
      display: flex;
      color: #170f49;
      span {
        font-size: 14px;
        width: 0;
        flex-grow: 1;
        align-self: center;
      }
    }
    margin-bottom: 40px;
    @include breakpoint(max-sm) {
      font-size: 16px;
    }
  }
  .pricing-btn {
    margin-top: auto;
  }
  height: 100%;
  display: flex;
  flex-direction: column;
  @include breakpoint(max-sm) {
    padding: 32px 24px;
  }
}
.pricing-btn {
  height: 55px;
  background: transparent;
  border: 1px solid var(--base-2);
  width: 100%;
  border-radius: 100px;
  color: var(--base-2);
  transition: all ease 0.3s;
  font-size: 15px;
  &:hover {
    background: var(--base-2);
    color: var(--white);
  }
}
.text-base {
  color: var(--base);
}
.cmn-btn {
  display: inline-block;
  padding: 16px 40px;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  color: var(--body-2);
  position: relative;
  border-radius: 100px;
  position: relative;
  span {
    position: relative;
    z-index: 1;
  }
  &::after,
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 100px;
    background-image: linear-gradient(90deg, #ffffff 0%, #ffffff40 100%);
  }
  &::after {
    inset: 2px;
    background: var(--base);
    transition: all ease 0.3s;
  }
  &:hover {
    color: var(--base);
    background: transparent;
    &::after {
      opacity: 0;
    }
    &::before {
      border: 2px solid var(--base);
      background: var(--body-2);
    }
  }
}
.cta-section {
  padding-bottom: 80px;
  @include breakpoint(max-sm) {
    padding-bottom: 40px;
  }
}
.cta-wrapper {
  border-radius: 8px;
  background: var(--base-2);
  padding: 40px 20px;
  text-align: center;
  .title {
    color: var(--white);
    margin-bottom: 22px;
  }
  .subtitle {
    margin-bottom: 32px;
    color: #d6e5f1;
    position: relative;
    display: inline-block;
    @include breakpoint(max-md) {
      max-width: 660px;
      margin-inline: auto;
    }
    .arrow {
      position: absolute;
      left: calc(100% + 14px);
      top: 13px;
      @include breakpoint(max-md) {
        display: none;
      }
    }
  }
  .cmn-btn {
    width: 100%;
    max-width: 322px;
    padding: 20px 40px;
    &::before {
      border: none;
    }
  }
  @include breakpoint(sm) {
    padding: 96px 25px 79px;
  }
}
footer {
  background: var(--body-2);
}
.footer-wrapper {
  display: flex;
  padding: 20px 0;
  align-items: center;
  min-height: 126px;
  .logo {
    width: 154px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  .footer-menu {
    display: flex;
    flex-wrap: wrap;
    column-gap: 32px;
    row-gap: 10px;
    li {
      a {
        color: var(--text);
        &:hover {
          color: var(--base);
        }
      }
    }
  }
  @include breakpoint(max-xl) {
    font-size: 15px;
  }
  @include breakpoint(max-lg) {
    font-size: 12px;
    line-height: 16px;
    .copyright {
      width: 90px;
      font-size: 12px;
    }
  }
  @include breakpoint(max-md) {
    .logo {
      width: 32px;
    }
    .footer-menu {
      width: 0;
      flex-grow: 1;
      gap: 10px;
    }
  }
}
.text-base-2 {
  color: var(--base-2);
}
.target-id {
  transform: translateY(-190px);
}
.login-section {
  background: var(--main-bg);
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 25px 0;
  .login-wrapper {
    background: var(--body-2);
    max-width: 614px;
    padding: 60px 100px;
    margin: 0 auto;
    border-radius: 8px;
    .logo {
      max-width: 214px;
      margin: 0 auto 32px;
    }
    .title {
      font-size: 30px;
      margin-bottom: 16px;
    }
    .subtitle {
      font-size: 16px;
      margin-bottom: 32px;
    }
    @include breakpoint(max-md) {
      padding: 40px;
    }
    @include breakpoint(max-sm) {
      padding: 24px 10px;
      .subtitle {
        font-size: 14px;
      }
    }
  }
}
.auth-logo .logo {
  max-width: 214px;
  margin: 0 auto 32px;
}
.auth-title {
  font-size: 30px;
  margin-bottom: 16px;
}
.auth-subtitle {
  font-size: 16px;
  margin-bottom: 32px;
  @include breakpoint(max-sm) {
    font-size: 14px;
    line-height: 20px;
  }
}

.backup-login {
  display:flex;
  flex-direction:'column';
  justify-content: center;
  align-items: center ;
  gap:2rem;
  outline: none;
  box-shadow: none;
  border: 1px solid var(--border);
  height: 60px;
  background: transparent;
  border-radius: 100px;
  padding-inline: 40px;
  width: 100%;
  color: var(--greys-blue-grey-900, #2d3e4d);
}
.or {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding-block: 10px;
  margin-block: 24px;
  display: flex;
  align-items: center;
  &::after,
  &::before {
    content: "";
    display: inline-block;
    width: 0;
    flex-grow: 1;
    background: var(--border);
    height: 1px;
  }
  span {
    margin-inline: 15px;
  }
}
.form--label {
  display: block;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: var(--title);
}
.eye {
  position: absolute;
  right: 8px;
  top: 0;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9;
}
.forget-pass {
  padding-bottom: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-decoration: underline;
  color: var(--title);
}
.form--control {
  border: 1px solid var(--border);
  background-color: transparent;
  border-radius: 30px;
  height: 50px;
  box-shadow: none !important;
  padding-inline: 24px;
  &:focus {
    box-shadow: none !important;
    background-color: transparent;
    border-color: var(--base);
  }
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  &::placeholder {
    color: #ced5da;
  }
}
.alt-txt {
  font-size: 14px;
  line-height: 1.5;
}
.form--check {
  font-size: 14px;
  margin: 0;
  display: flex;
  align-items: center;
  .form-check-label {
    width: 0;
    flex-grow: 1;
    margin-top: 1.4px;
  }
  input {
    width: 20px;
    height: 20px;
    border-radius: 50% !important;
    box-shadow: none !important;
    border-color: var(--border);
    &:checked {
      background-color: var(--base);
      border-color: var(--base);
    }
    margin: 0;
    margin-right: 9px;
  }
  a {
    color: #2f80ed;
  }
}
.submit-btn {
  display: inline-block;
  padding: 0 40px;
  line-height: 32px;
  color: var(--body-2);
  position: relative;
  border-radius: 100px;
  position: relative;
  background: var(--base);
  border: 1px solid var(--base);
  transition: all ease 0.3s;
  font-size: 14px;
  font-weight: 500;
  height: 50px;
  width: 100%;
  &:hover {
    color: var(--base);
    background: transparent;
    &::after {
      opacity: 0;
    }
    &::before {
      border: 2px solid var(--base);
      background: var(--body-2);
    }
  }
}
.forget-pass-txt {
  font-size: 14px;
  color: #2f80ed;
}
.txt-sm {
  font-size: 14px;
  line-height: 1.5;
}
.register-wrapper {
  max-width: 432px;
  margin: 0 auto;
  padding: 40px 0;
}
.register-left {
  background: var(--base-2);
  height: 100%;
  .logo {
    width: 256px;
    margin-bottom: 220px;
  }
  .register-left-inner {
    max-width: 427px;
    margin: 0 auto;
    padding: 40px 0;
    min-height: 100vh;
    overflow: hidden;
    .swiper {
      overflow: visible;
    }
    .slide-item {
      color: #d8e1e8;
      .title {
        font-size: 48px;
        font-weight: 500;
        line-height: 60px;
        color: var(--white);
        display: inline;
        &::after {
          content: "";
          width: 218px;
          height: 2px;
          background: var(--section);
          display: inline-block;
          margin-bottom: 15px;
          margin-left: 18px;
        }
        margin: 0;
      }
      p {
        margin-top: 49px;
      }
    }
    .slide-item-elevate {
      color: #d8e1e8;
      .title {
        font-size: 48px;
        font-weight: 500;
        line-height: 60px;
        color: var(--white);
        display: inline;
        &::after {
          position: relative;
          top: 50px;
          right: 200px;
          content: "";
          width: 218px;
          height: 2px;
          background: var(--section);
          display: inline-block;
          margin-bottom: 15px;
          margin-left: 18px;
        }
        margin: 0;
      }
      p {
        margin-top: 49px;
      }
    }
    .swiper-pagination {
      position: absolute;
      top: calc(100% + 180px);
      display: flex;
      width: 100%;
      column-gap: 20px;
      .swiper-pagination-bullet {
        width: 20px;
        height: 20px;
        background: var(--white);
        margin: 0;
        padding: 0;
        * {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
.register-section {
  @include breakpoint(max-lg) {
    padding: 60px 0;
    .register-wrapper {
      background: var(--body-2);
      padding: 24px;
      border-radius: 8px;
    }
  }
}
.onboarding-hero {
  padding: 121px 0 268px;
  background: var(--base-2);
  text-align: center;
  .title {
    color: var(--body-2);
    font-weight: 500;
    img {
      transform: translateY(-100%);
    }
    margin-bottom: 30px;
  }
  .subtitle {
    font-weight: 500;
    color: #d8e1e8;
    font-size: 30px;
  }
  @include breakpoint(max-xl) {
    .subtitle {
      font-size: 24px;
    }
  }
  @include breakpoint(max-lg) {
    .subtitle {
      font-size: 20px;
    }
  }
  @include breakpoint(max-md) {
    padding: 60px 0 448px;
    .title {
      font-size: 24px;
      img {
        width: 24px;
      }
    }
    .subtitle {
      font-size: 16px;
    }
  }
}
.role-btn {
  background: transparent;
  border: 1px solid var(--border);
  background: transparent;
  outline: none;
  box-shadow: none;
  font-size: 14px;
  padding: 13.5px 15.5px;
  border-radius: 100px;
  text-align: left;
  transition: all ease 0.3s;
  &.active {
    background: var(--base);
    border-color: var(--base);
    color: var(--white);
    box-shadow: 5px 10px 30px rgba(35, 181, 116, 0.3) !important;
  }
}
.__sm-btn-shadow {
  box-shadow: 5px 10px 30px rgba(35, 181, 116, 0.3) !important;
}
.onboarding-wrapper {
  padding: 32px;
  box-shadow: 5px 20px 40px 0px rgba(12, 87, 155, 0.1);
  background: var(--body-2);
  border-radius: 8px;
  margin-bottom: 42px;
  margin-top: -202px;
  @include breakpoint(max-md) {
    padding: 10px;
    margin-top: -398px;
  }
}
.gap-md-20px {
  --bs-gutter-x: 10px;
}
.__btn {
  font-size: 14px;
  color: var(--body-2);
  background: var(--base);
  border-radius: 100px;
  text-transform: capitalize;
  border: 1px solid var(--base);
  transition: all ease 0.3s;
  padding: 13px 32px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  &:hover {
    color: var(--base);
    background: var(--white);
  }
}
.__btn-outline {
  font-size: 14px;
  border-radius: 100px;
  text-transform: capitalize;
  border: 1px solid var(--base);
  transition: all ease 0.3s;
  padding: 13px 32px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: transparent;
  color: var(--base);
  justify-content: center;
  &:hover {
    background: var(--base);
    color: var(--body-2);
  }
  &.btn-black {
    &:not(:hover) {
      border-color: var(--title);
      color: var(--title);
    }
  }
}
.__btn-grp {
  @include breakpoint(md) {
    column-gap: 32px !important;
    .__btn {
      column-gap: 32px !important;
    }
  }
}
.onboarding-section-wrapper {
  background: var(--main-bg);
  min-height: 100vh;
}
.onboarding-banner-2 {
  padding: 149px 0 110px;
  background: var(--base-2);
  text-align: center;
  .title {
    margin: 0;
    color: var(--white);
  }
  @include breakpoint(max-md) {
    padding: 60px 0;
    .title {
      font-size: 24px;
    }
  }
}
.onboarding-wrapper-2 {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  padding: 44px 0;
  .item {
    width: calc((100% / 3) - 22px);
    padding: 30px 20px;
    border-radius: 8px;
    background: var(--body-2);
    text-align: center;
    cursor: pointer;
    transition: all ease 0.3s;
    .icon {
      width: 57px;
      height: 57px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid var(--border);
      margin: 0 auto 18px;
      background: var(--body-2);
    }
    h5 {
      font-weight: 500;
      font-size: 16px;
      margin: 0;
    }
    &.active {
      background: var(--base-2);
      .icon {
        border-color: var(--body-2);
      }
      h5 {
        color: var(--white);
      }
    }
  }
  @include breakpoint(max-lg) {
    gap: 24px;
    .item {
      width: calc((100% / 3) - 16px);
    }
  }
  @include breakpoint(max-sm) {
    padding-top: 24px;
    gap: 10px;
    row-gap: 24px;
    .item {
      width: calc((100% / 2) - 5px);
    }
  }
}
.__btn-grp-2 {
  @media screen and (max-width: 575px) {
    column-gap: 24px !important;
    .__btn,
    .__btn-outline {
      padding-inline: 25px;
      column-gap: 16px !important;
    }
  }
  @media screen and (max-width: 450px) {
    column-gap: 16px !important;
    .__btn,
    .__btn-outline {
      padding-inline: 14px;
      column-gap: 10px !important;
    }
  }
  @media screen and (max-width: 340px) {
    column-gap: 10px !important;
    .__btn,
    .__btn-outline {
      padding-inline: 10px;
      column-gap: 5px !important;
      padding-block: 10px;
    }
  }
}
.success-modal {
  .icon {
    margin-bottom: 32px;
    display:flex;
    justify-content: center;
    svg {
      @include breakpoint(max-sm) {
        width: 121px;
        height: 121px;
      }
    }
  }
  h5 {
    margin-bottom: 32px;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 40px;
  }
}
.success-reg-modal {
  .modal-dialog {
    max-width: 480px;
    .modal-body {
      padding: 32px;
      @include breakpoint(max-sm) {
        padding-inline: 20px;
      }
    }
  }
}
.main-section {
  display: flex;
  align-items: flex-start;
  background: var(--main-bg);
  min-height: 100vh;
  aside {
    width: 284px;
    background: var(--white);
    transition: all ease 0.3s;
    z-index: 1000;
    position: sticky;
    top: 0;
    .inner-div {
      height: 100vh;
      display: flex;
      flex-direction: column;
    }
    .logo-area {
      padding: 27px 20px;
      border-bottom: 1px solid var(--section);
      position: sticky;
      top: 0;
      .logo {
        width: 150px;
        max-height: 40px;
        margin: 0 auto;
        object-fit: contain;
      }
      position: relative;
      .angle-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -20px;
        cursor: pointer;
      }
    }
    .sidebar-menu-area {
      padding: 32px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      .sidebar-menu {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 24px;
        li {
          a {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            color: var(--text);
            display: flex;
            align-items:center;
            gap: 10px;
            padding: 12px 18px;
            border-radius: 100px;
            &.active {
              background: var(--main-bg);
            }
          }
        }
        @include breakpoint(max-lg) {
          margin-bottom: 48px;
        }
      }
    }
    &.active {
      @include breakpoint(lg) {
        width: 128px;
        .logo-area {
          .logo {
            height: 33px;
            width: 43px;
            img {
              height: 40px;
              object-fit: contain;
              margin-top: -4px;
            }
          }
          .angle-icon {
            transform: translateY(-50%) rotate(180deg);
          }
        }
        .create-btn {
          padding-inline: 10px;
        }
        .sidebar-menu {
          li {
            a {
              .txt {
                display: none;
              }
            }
          }
        }
        .__btn .txt,
        .account-update-info {
          display: none;
        }
      }
    }
    @include breakpoint(max-lg) {
      position: fixed;
      z-index: 1000;
      top: 0;
      left: 0;
      width: 390px;
      max-width: 97%;
      overflow-y: auto;
      .close-sidebar {
        position: absolute;
        right: 20px;
        top: 30px;
        z-index: 1;
      }
      .inner-div {
        max-width: 284px;
        .logo-area {
          padding-bottom: 0;
          border: none;
          .logo {
            margin-left: 12px;
          }
        }
      }
      transition: all ease 0.3s;
      &:not(.active) {
        transform: translateX(-100%);
        // opacity: 0;
        visibility: hidden;
      }
    }
  }
  article {
    width: 0;
    flex-grow: 1;
  }
  
}
.account-update-info {
  padding: 32px 18px;
  border-radius: 8px;
  background: var(--base-2);
  text-align: center;
  margin-top: auto;
  .__btn {
    padding-block: 8px;
    height: 42px;
  }
  color: #d8e1e8;
  .title {
    color: var(--body-2);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 10px;
  }
  font-size: 14px;
}
.mb-32 {
  margin-bottom: 32px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.article-header {
  display: flex;
  justify-content: space-between;
  padding-block: 20px;
  padding-inline: 48px;
  background: var(--body-2);
  min-height: 87px;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
  .name {
    margin: 0;
    font-size: 20px;
    @include breakpoint(max-sm) {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
    }
  }
  .right-icons {
    display: flex;
    align-items: center;
    column-gap: 28px;
    .icon {
      width: 48px;
    }
    @include breakpoint(max-md) {
      column-gap: 20px;
      .icon {
        width: 30px;
      }
    }
    img,
    svg {
      max-width: 100%;
      height: unset;
    }
  }
  @include breakpoint(max-md) {
    min-height: 80px;
    padding-top: 30px;
    padding-bottom: 10px;
    padding-inline: 24px;
  }
}
.dropdown-btn {
  display: flex;
  align-items: center;
  text-align: left;
  gap: 10px;
  font-size: 14px;
  &::after {
    content: "";
    width: 10px;
    height: 10px;
    display: inline-block;
    border-right: 2px solid var(--title);
    border-bottom: 2px solid var(--title);
    transform: rotate(45deg);
    margin-left: 8px;
    @include breakpoint(xl) {
      margin-left: 18px;
    }
    @include breakpoint(max-md) {
      display: none;
    }
  }
  img {
    object-fit: cover;
    aspect-ratio: 1;
    border-radius: 50%;
  }
  @include breakpoint(max-md) {
    .info {
      display: none;
    }
    img {
      width: 30px;
    }
  }
  .name {
    color: var(--base-2);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0;
  }
}
body *::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(#000, 0.3);
  width: 3px;
  height: 3px;
}
body *::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
body *::-webkit-scrollbar-thumb {
  background-color: var(--text);
  outline: 1px solid var(--text);
  width: 3px;
  height: 3px;
  border-radius: 5px;
}
.article-children {
  padding: 24px;
  @include breakpoint(xl) {
    padding: 32px;
  }
}
.home-item {
  position: relative;
  text-align: center;
  padding-bottom: 37px;
  &::before {
    content: "";
    inset: 0;
    top: 58px;
    position: absolute;
    background: var(--white);
    border-radius: 8px;
  }
  .icon {
    width: 126px;
    height: 126px;
    border: 18px solid var(--main-bg);
    border-radius: 50%;
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px;
    position: relative;
    z-index: 1;
    svg {
      width: 38px;
      height: 38px;
    }
  }
  .name {
    position: relative;
    z-index: 1;
    transition: all ease 0.3s;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }
  .link {
    position: absolute;
    inset: 0;
    z-index: 2;
  }
  &:hover {
    .name {
      color: var(--base);
    }
  }
}
.recent-project-section {
  padding-top: 44px;
  @include breakpoint(max-md) {
    padding-top: 29px;
  }
}
.__section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  h5 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }
  margin-bottom: 24px;
}
.see-all {
  color: #2f80ed;
  font-size: 16px;
  font-weight: 500;
  svg {
    width: 24px;
  }
}
.__status {
  display: flex;
  align-items: center;
  gap: 10px;
  &::before {
    width: 10px;
    height: 10px;
    content: "";
    background: #eb5757;
    border-radius: 50%;
  }
  &.success {
    &::before {
      background: var(--base);
    }
  }
}
.__table {
  border-collapse: separate;
  border-spacing: 0 4px;
  font-family:'Poppins' ;
  
  color: var(--text);
  margin: 0;
  .th {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    &::after {
      border-right: 2px solid var(--text);
      border-bottom: 2px solid var(--text);
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      border-radius: 0 0 4px 0;
      transform: rotate(45deg);
    }
  }
  tr {
    td,
    th {
      border: none;
      padding: 21px 24px;
      vertical-align: middle;
      color: var(--text);
    }
    td {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
    th {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      background: var(--section);
    }
  }
}
.no-break {
  white-space: pre;
}
.max-w-223 {
  max-width: 223px;
}
.h-42 {
  height: 42px;
}
.no-gutter {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;
}
.search-form {
  position: relative;
  .form--control {
    height: 44px;
    padding-left: 50px;
  }
  button {
    position: absolute;
    left: 0;
    top: 0;
    height: 44px;
    width: 52px;
    z-index: 3;
  }
  @include breakpoint(max-sm) {
    width: 193px;
  }
  @include breakpoint(md) {
    width: 311px;
    .form--control,
    button {
      height: 48px;
    }
  }
}
.dashboard-card {
  padding: 24px;
  min-height: 165px;
  border-radius: 8px;
  background: var(--body-2);
  &-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 32px;
    h6 {
      color: #000;
      font-size: 18px;
      margin: 0;
      width: 0;
      flex-grow: 1;
    }
    @include breakpoint(max-sm) {
      margin-bottom: 17px;
    }
  }
  .count {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: var(--base-2);
  }
}

.chart2-padding-rm {
  padding: 0 !important;
}
@media screen and (max-width: 485px) {
  .chart2-padding-rm {
    padding: 24px !important;
  }
  .chart-1{
    flex-wrap: nowrap;
  }
}
.chart-1 {
  padding: 10px;
  border-radius: 8px;
  background: var(--body-2);
  display: flex;
  align-items: center;
  gap: 15px;
  .pie-chart {
    width: 140px;
    // width: 203px;
    position: relative;
  }
  .pie-chart-2 {
    width: 103px !important;
    position: relative;
  }
  .chart-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--title);
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
    width: 0;
    li {
      display: flex;
      column-gap: 12px;
      .info {
        width: 0;
        flex-grow: 1;
      }
      .icon {
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }
    }
  }
  .chart-label-2 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--title);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    flex-grow: 1;
    width: 0;
    li {
      display: flex;
      column-gap: 24px;
      .info {
        width: 0;
        flex-grow: 1;
      }
      .icon {
        width: 22px;
        height: 22px;
        border-radius: 50%;
      }
    }
  }

  @media screen and (max-width: 1439px) {
    .pie-chart {
      // width: 180px;
    }
    .chart-label {
      font-size: 15px;
      li {
        column-gap: 12px;
      }
    }
  }
  @media screen and (min-width: 1200px) and (max-width: 1350px) {
    .pie-chart {
      width: 120px;
    }
    column-gap: 15px;
    padding: 10px;
    .chart-label {
      font-size: 12px;
      gap: 12px;
      li {
        column-gap: 10px;
        .icon {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  @media screen and (max-width: 490px) {
    flex-wrap: nowrap;
    justify-content: center;
    .pie-chart {
      width: 203px;
      position: relative;
    }
    .chart-label {
      width: 100%;
      max-width: 160px;
      margin: 0 auto;
      gap: 20px;
    }
  }
}
.bg-body-2 {
  border-radius: 8px;
  background: var(--body-2);
}
.center-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 14px;
  font-weight: 00;
  background: var(--body-2);
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 28px);
  aspect-ratio: 1;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  .count {
    font-size: 23px;
    font-weight: 600;
    color: var(--base-2);
  }
}
.chart-wrapper {
  padding: 24px;
  border-radius: 8px;
  background: var(--body-2);
  @include breakpoint(max-sm) {
    padding: 18px;
    padding-right: 0;
  }
}
.yaxis {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
  gap: 20px;
  > div {
    display: flex;
    align-items: center;
    gap: 12px;
    span {
      width: 30px;
    }
    position: relative;
    .xaxis {
      position: absolute;
      left: 0;
      bottom: 14px;
      width: calc(100% - 50px);
      // height: calc(100% - 15px);
      height: 180px;
      display: flex;
      flex-direction: column;
    }
    .xaxis-data-bars {
      flex-grow: 1;
      gap: 5px;
      display: flex;
      align-items: flex-end;
      justify-content: space-evenly;
      .item {
        width: 5px;
        flex-grow: 1;
        max-width: 16px;
        background: var(--base-2);
        border-radius: 6px;
        margin: 0 auto;
      }
    }
    .xaxis-data {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      gap: 30px;
      position: absolute;
      top: calc(100% + 5px);
      left: 0;

      .item {
        width: 5px;
        flex-grow: 1;
        // max-width: 50px;
        text-align: center;
        margin: 0 auto;
      }
    }
  }
  position: relative;
}
.xaxis {
  display: flex;
  font-size: 12px;
  font-weight: 500;
  margin-left: 30px;
  @include breakpoint(sm) {
    font-size: 16px;
  }
}
.top-right-stick {
  position: absolute;
  top: 24px;
  right: 24px;
}
.popup-modal {
  .modal-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }
  .modal-title-2 {
    @include breakpoint(max-sm) {
      font-size: 18px;
    }
  }
}
.__breadcrumb {
  width: 50px;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  li {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    &:not(:last-child) {
      display: flex;
      align-items: center;
      &::after {
        border-right: 2px solid var(--text);
        border-bottom: 2px solid var(--text);
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 0 0 4px 0;
        transform: rotate(-45deg);
        margin: 0 8px;
      }
    }
    @include breakpoint(max-xl) {
      font-size: 12px;
      line-height: 1.4;
      &:not(:last-child) {
        &::after {
          margin-inline-start: 0;
          margin-inline-end: 4px;
        }
      }
    }
  }
}
.description-popup {
  .modal-dialog {
    max-width: 444px;
  }
}
.modal-content {
  border: none;
}
.w-0 {
  width: 0 !important;
}
.gap-20px {
  gap: 20px;
}
label {
  cursor: pointer;
}
.btn-close {
  box-shadow: none !important;
}
@include breakpoint(sm) {
  .max-sm-216 {
    max-width: 216px;
  }
}
.jobs-single-heading {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 8px;
  background: var(--body-2);
  padding: 15px 20px;
  gap: 16px;
  position: relative;
  @include breakpoint(max-xl) {
    column-gap: 12px;
  }
  .item {
    position: relative;
    @media screen and (min-width: 1400px) {
      min-width: 120px;
      // border-right:0.5px solid
    }
    @media screen and (min-width: 1540px) {
      min-width: 140px;
      // border-right:1px solid

    }
    @include breakpoint(max-xl) {
      width: 20%;
      flex-grow: 1;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        width: 25%;
      }
    }
    @include breakpoint(max-md) {
      position: initial;
    }
    @include breakpoint(max-sm) {
      &:nth-child(2),
      &:nth-child(3) {
        max-width: 80px;
      }
    }
  }
  .subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 10px;
    @include breakpoint(max-sm) {
      font-size: 12px;
    }
  }
  .name {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    @include breakpoint(max-sm) {
      font-size: 12px;
    }
  }
}
.job-heading-menu {
  position: absolute;
  top: calc(100% + 20px);
  left: -20px;
  width: 95vw;
  max-width: 560px;
  background: var(--body-2);
  z-index: 99;
  box-shadow: 0px 30px 60px 0px rgba(66, 114, 92, 0.2);
  .__table {
    border-spacing: 0;
    tr td {
      padding-block: 16px;
      cursor: pointer;
    }
  }
  @include breakpoint(max-md) {
    width: 100%;
    max-width: 100%;
    left: 0;
    top: 100px;
  }
  @include breakpoint(max-sm) {
    .__table tr td,
    .__table tr th {
      font-size: 12px;
      padding: 10px;
    }
  }
  transition: all ease 0.3s;
  &:not(.active) {
    opacity: 0;
    visibility: hidden;
    transform: translateY(5px);
  }
}
.job-setting {
  position: absolute;
  top: calc(100% + 20px);
  left: calc(100% - 28px);
  background: var(--body-2);
  border-radius: 8px;
  padding: 10px 0;
  width: 100vw;
  max-width: 252px;
  box-shadow: 0px 30px 60px 0px rgba(66, 114, 92, 0.2);
  z-index: 99;
  .job-setting-inner {
    display: flex;
    flex-direction: column;
    .form-check {
      padding: 14px 20px;
      display: flex;
      margin: 0;
      align-items: center;
      gap: 10px;
      * {
        margin: 0;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #eff4fa;
      }
    }
    .form-check-input {
      width: 16px;
      height: 16px;
      border-radius: 3px !important;
      margin-top: -3px;
    }
  }
  transition: all ease 0.3s;
  &:not(.active) {
    opacity: 0;
    visibility: hidden;
    transform: translateY(5px);
  }
  @include breakpoint(max-md) {
    left: 128px;
    top: 100px;
  }
  @media screen and (max-width: 430px) {
    left: 100px;
  }
  @media screen and (max-width: 391px) {
    left: 89px;
  }
  @media screen and (max-width: 380px) {
    left: 70px;
  }
  @media screen and (max-width: 360px) {
    left: 30px;
  }
}
.text--danger {
  color: #eb5757 !important;
}
.text--success {
  color: #23b574 !important;
}
.text--yellow {
  color: #ffb300 !important;
}
.job-tab-group {
  margin-top: 32px;
  margin-bottom: 2px;
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      flex-grow: 1;
      a {
        display: block;
        padding: 28px 24px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: var(--text);
        border-radius: 8px 8px 0px 0px;
        &.active {
          background: var(--body-2);
          border:#23b574 solid 1px;
          border-bottom:0
        }
        @include breakpoint(max-xl) {
          padding: 20px 10px;
        }
      }
      @include breakpoint(max-sm) {
        width: 100%;
        a {
          padding: 24px 10px;
          font-size: 12px;
        }
      }
    }
  }
}
.jobs-management {
  padding: 32px;
  @include breakpoint(max-md) {
    padding: 32px 10px;
  }
}
.dashboard-form-2,
.dashboard-form {
  .form--label {
    font-weight: 500;
    margin-bottom: 12px;
  }
  .form--control:where(input) {
    height: 42px;
  }
}
.dashboard-form-2 {
  .form--label {
    margin-bottom: 24px;
  }
}
.__txtarea-1 {
  // height: 399px;
  height: 598px;
  border-radius: 8px;
  padding: 24px;
  @include breakpoint(max-md) {
    height: 322px;
  }
}
.image-uploader-1 {
  border-radius: 8px;
  border: 1px dashed var(--secondary-900, #23b574);
  background: var(--primary-100, #fefeff);
  min-height: 188px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0;
  position: relative;
  .dropzone {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.h-50px {
  height: 50px;
}
.dropdown .form-control:focus .angle {
  transform: rotate(180deg);
}
.__dropdown-menu-4 {
  border-radius: 8px;
  background: #fff;
  border: none;
  box-shadow: 0px 30px 60px 0px rgba(66, 114, 92, 0.2);
  overflow-y: auto;
  color: #b3bbc1;
  padding-block: 16px;
  .account-menu {
    max-height: 436px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    li {
      padding: 10px 32px;
      label {
        color: #2d3e4d;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        display: block;
        &:active {
          color: var(--base);
        }
      }
    }
  }
}
.__dropdown-menu {
  padding: 32px;
  border-radius: 8px;
  background: #fff;
  border: none;
  box-shadow: 0px 30px 60px 0px rgba(66, 114, 92, 0.2);
  font-size: 14px;
  overflow-y: auto;
  max-height: 85vh;

  &:hover {
    display: block !important;
  }
  .txt {
    margin-bottom: 14px;
    strong {
      font-weight: 500;
    }
  }
  .account-menu {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 18px;
    li {
      a {
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--text);
        padding-block: 6px;
        &:hover {
          color: var(--base);
        }
        span {
          width: 0;
          flex-grow: 1;
        }
      }
    }
  }
  @media screen and (min-width: 768px) {
    right: 0 !important;
    left: unset !important;
  }
}
.__dropdown-menu-2 {
  padding: 32px;
  border-radius: 8px;
  background: #fff;
  border: none;
  box-shadow: 0px 30px 60px 0px rgba(66, 114, 92, 0.2);
  font-size: 14px;
  width: 288px;
  max-height: 85vh;
  overflow-y: auto;
  &:hover {
    display: block !important;
  }
  @media screen and (max-width: 459px) {
    padding: 20px;
    .ff-txt {
      font-size: 12px;
    }
  }
  @media screen and (min-width: 350px) and (max-width: 459px) {
    // width: 337px;
    left: unset !important;
    right: 24px !important;
    width: calc(100vw - 48px);
    position: fixed !important;
    transform: translateX(0) !important;
    top: 80px !important;
  }
  @media screen and (min-width: 460px) {
    width: 419px;
  }
  @media screen and (min-width: 768px) {
    right: 0 !important;
    left: unset !important;
  }
  textarea {
    background: var(--main-bg);
    border-radius: 8px;
    height: 158px;
    resize: none;
  }
  .image-uploader-1 {
    min-height: 87px;
  }
}
.my-32 {
  margin-block: 32px;
}
.account-layout-header {
  position: relative;
  padding-bottom: 36px;
  &::before {
    content: "";
    top: -32px;
    left: 0;
    right: 0;
    position: absolute;
    background: var(--base-2);
    height: 92px;
    z-index: 0;
    border-radius: 0px 0px 8px 8px;
    @include breakpoint(max-sm) {
      top: 0;
    }
  }
  @include breakpoint(max-sm) {
    padding-top: 34px;
  }
  .content {
    position: relative;
    z-index: 1;
    text-align: center;
    font-size: 20px;
    .name {
      font-size: 24px;
      margin-block: 20px 14px;
      font-weight: 500;
    }
  }
}
.upload-img {
  width: 122px;
  aspect-ratio: 1;
  position: relative;
  margin: 0 auto;
  img {
    width: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    object-fit: cover;
  }
  .upload-icon {
    position: absolute;
    bottom: 16px;
    right: -18px;
  }
}
.personal-details-title {
  font-size: 20px;
  margin-bottom: 16px;
}
.p-32-10 {
  padding: 32px 10px;
  @include breakpoint(sm) {
    padding: 32px;
  }
}
.radio-grp {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.radio-item {
  border-radius: 8px;
  background: var(--body-2);
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 105px;
  h6 {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 6px;
  }
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  .form-check-input {
    margin: 0;
    width: 30px;
    height: 30px;
    outline: 2px solid #6d9ac3;
    border: none;
    &:checked {
      background: var(--base);
      border: 4px solid var(--white);
      outline: 2px solid var(--base);
      box-shadow: none;
    }
  }
}
.__badge-sm {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  background: var(--base-2);
  color: var(--body-2);
  padding: 4px 6px;
  border-radius: 12px;
  display: inline-block;
}
.form--switch {
  .form-check-input {
    width: 33px;
    height: 17.325px;
    box-shadow: none;
    &:checked {
      background-color: var(--base-2);
      border-color: var(--base-2);
    }
  }
}
.switch-item {
  display: flex;
  gap: 9px;
  padding: 32px;
  .info {
    width: 0;
    flex-grow: 1;
    h6 {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 5px;
    }
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
.mt-lg--24 {
  @include breakpoint(lg) {
    margin-top: -24px;
  }
}
.__placeholder-dark {
  .form--control {
    &::placeholder {
      color: var(--title);
    }
    border-radius: 8px !important;
  }
}
textarea {
  resize: none;
}
.save-info {
  @include breakpoint(max-sm) {
    gap: 10px !important;
    flex-wrap: wrap;
    width: 100%;
    .__btn,
    .__btn-outline {
      padding-inline: 6px;
      width: 140px;
      flex-grow: 1;
    }
  }
}
.ql-toolbar.ql-snow {
  border-radius: 8px 8px 0 0;
}
.ql-container.ql-snow {
  border-radius: 0 0 8px 8px;
  border-top: none !important;
  min-height: 572px;
  @include breakpoint(max-md) {
    min-height: 522px;
  }
}
.ql-blank {
  min-height: 572px;
  @include breakpoint(max-md) {
    min-height: 522px;
  }
}
.__dropdown-menu-3 {
  // padding: 24px;
  border-radius: 8px;
  background: #fff;
  border: none;
  box-shadow: 0px 30px 60px 0px rgba(66, 114, 92, 0.2);
  font-size: 12px;
  overflow-y: auto;
  max-height: 85vh;
  ul {
    li {
      button {
        height: 36px;
        padding-inline: 24px;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #eff4fa;
      }
    }
    max-height: 156px;
    overflow-y: auto;
  }
}
.__output-text {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 8px;
  min-height: 627px !important;
  @include breakpoint(max-md) {
    min-height: 547px !important;
  }
}
.btn-sm-grp {
  .__btn,
  .__btn-outline {
    font-size: 12px;
    padding: 8px 14px;
  }
}
.__item-container {
  display: flex;
  align-items: flex-start;
  .open-close {
    @include breakpoint(lg) {
      margin-top: 95px;
      &:not(.active) {
        transform: translateX(-50%);
      }
    }
    @include breakpoint(max-lg) {
      width: 100px;
      height: 50px;
      transform: rotate(90deg);
    }
  }
  .__item {
    transition: all ease 0.3s;
    width: 0;
    flex-grow: 1;
    &:not(.active) {
      display: none;
    }
  }
  @include breakpoint(max-lg) {
    flex-direction: column;
    .__item {
      width: 100%;
    }
  }
}
.select-rounded {
  select,
  input {
    border-radius: 8px;
  }
}
.mt-32 {
  margin-top: 32px;
}
.text-dark {
  &::placeholder {
    color: var(--title);
  }
}

.accordion-button {
  background-color: #064b84 !important;
  color: #fefeff !important;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: white;
}
.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
.accordion {
  border-radius: 8px !important;
}
