.center-body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  /****** center box
	width: 300px;
	height: 300px;
	border: solid 1px #aaa;
	******/
}
body {
  background-color: #202628;
}

.loader-circle-6 {
  animation: loader-circle-6-spin 1s linear infinite;
  border: solid 8px rgb(6, 120, 6);
  border-top: solid 8px transparent;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  display: inline-block;
  color:rgb(3, 69, 3)
}
@keyframes loader-circle-6-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.popup-container {
  position: absolute;
opacity:0.4;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Add this CSS to your stylesheet */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: white;
}

.user-list-container {
  margin: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  overflow-x: scroll;
}

.user-table th,
.user-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.user-table th {
  background-color: #f2f2f2;
}

.user-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.search-feature-container {
  display: flex;
  gap: 10px;
}

.feature-box {
  min-height: 300px;
  height: 300px;
  overflow: auto;
  border: 1px solid #ced5da;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: none !important;
  padding: 24px;
}
.feature-box:focus {
  outline: none;
  border:1px solid !important;
  border-color:var(--base) !important;
}

@media (max-width: 768px) {
  .search-feature-container {
    flex-wrap: wrap;
  }
  .jobtitle-box,
  .feature-box {
    width: 100% !important;
  }
}
